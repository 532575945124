.y4eJoW_tabs {
  flex-direction: row;
  gap: 8px;
  display: flex;
}

.y4eJoW_tabs .y4eJoW_tab {
  cursor: pointer;
  height: 44px;
  padding-top: 2px;
}

.y4eJoW_tabs .y4eJoW_tab .y4eJoW_tab-content {
  height: 20px;
  flex-direction: row;
  align-items: center;
  margin: 10px 8px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
}

.y4eJoW_tabs .y4eJoW_tab .y4eJoW_tab-content > i {
  text-align: center;
  width: 20px;
  height: 20px;
  margin-right: 4px;
}

.y4eJoW_tabs .y4eJoW_tab .y4eJoW_tab-content > i > svg {
  width: 20px;
  height: 20px;
}

.y4eJoW_tabs .y4eJoW_tab > hr {
  display: none;
}

.y4eJoW_tabs .y4eJoW_tab:hover {
  background-color: #f3f2f1;
}

.y4eJoW_tabs .y4eJoW_tab.y4eJoW_selected {
  font-weight: 600;
}

.y4eJoW_tabs .y4eJoW_tab.y4eJoW_selected > hr {
  z-index: 1;
  height: 2px;
  background-color: #25d0b1;
  border: 0;
  margin: 0 8px;
  transition: all .3s;
  display: block;
  position: relative;
}

.y4eJoW_tabs .y4eJoW_tab.y4eJoW_selected:hover hr {
  margin: 0;
  transition: all .3s;
}

/*# sourceMappingURL=bundle.cabc27ad.css.map */
